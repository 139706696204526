var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ec8301c94252d67472ec17ad75a50a2fdbcb3d83"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Using a relative URL will not trigger a preflight CORS request, so no events will be blocked, because the ad-blocker will not treat these events as third-party requests.
  // {@see {@link https://docs.sentry.io/platforms/javascript/troubleshooting/#using-the-tunnel-option}
  tunnel: '/api/tunnel',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: true,
  maxBreadcrumbs: 50,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // integrations and plugins
  integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
  //  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^\//,
    /^https:\/\/vercel\.app\/api/,
    /^https:\/\/meveth\.xyz\/api/,
  ],
});
